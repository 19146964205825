import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ErrorComponent, LoadingComponent } from "@components";
import {
  AuthLayout,
  MainLayout,
  StaticPagesLayout,
  AdminLayout,
} from "@layouts";
import {
  ForgotPassword,
  Login,
  SetPassword,
  Signup,
  AdminLogin,
} from "@pages/Authentication";
import {
  ChangePassword,
  EditProfile,
  Profile,
  Subscription,
} from "@pages/Settings";
import {
  Pricing,
  Home,
  Contact,
  TermsConditions,
  PrivacyPolicy,
  About,
  Features,
} from "@pages/StaticPages";
import { NotFound } from "@pages/NotFound";
import { Dashboard, Thankyou } from "@pages/User";
import { AdminPanel, Users, Queries } from "@pages/Admin";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { FETCH_USER } from "@graphql/queries";
import { userData } from "@store/authSlice";
import { OtpVerification } from "@pages/Authentication/OtpVerification";
import { ContactManagement } from "@pages/User/Contact Management";
import { ImportDetails } from "@pages/User/ImportDetails";
import { Errors } from "@pages/User/ImportDetails/Errors";
import { ListManagement } from "@pages/User/List Management";
import { Transactions } from "@pages/User/Transactions";
import { Archive } from "@pages/User/Archive";
import { BalanceTopUp } from "@pages/User/Balance Topup";
import { ArchivedLists } from "@pages/User/Archive/ArchivedLists";
import { ArchivedCompaigns } from "@pages/User/Archive/ArchivedCompaigns";
import { ArchivedTemplates } from "@pages/User/Archive/ArchivedTemplates";
import { ArchivedListsViewMore } from "@pages/User/Archive/ArchivedListsViewMore";
import { Campaigns } from "@pages/User/Campaigns";
import { SmsLogs } from "@pages/User/Campaigns/SmsLogs";
import { Templates } from "@pages/User/Campaigns/Templates";
import { OptoutList } from "@pages/User/List Management/OptoutList";
import { ManageContacts } from "@pages/User/List Management/ManageContacts";
import { TagsManagement } from "@pages/Admin/Tags Management";
import { PackageManagement } from "@pages/Admin/Package Management";
import { ArchivedPackages } from "@pages/Admin/Package Management/ArchivedPackages";
import { DraftCampaigns } from "@pages/User/Campaigns/DraftCampaigns";
import { ScheduledCampaign } from "@pages/User/Campaigns/ScheduledCampaign";
import { InProgressCampaign } from "@pages/User/Campaigns/InProgressCampaign";
import { SentCampaign } from "@pages/User/Campaigns/SentCampaign";
import { OptOutLink } from "@pages/StaticPages/OptOutLink";
import { CampaignStats } from "@pages/User/Campaigns/CampaignStats";

export const Routers = () => {
  const authState = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const userFromState = authState.user?.id && authState.user;
  const { loading, error, data } = useQuery(FETCH_USER, {
    skip: userFromState || !authState.isAuthenticated,
  });

  useEffect(() => {
    const user = userFromState || (data && data?.fetchUser);
    dispatch(userData(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.fetchUser]);

  if (loading) return <LoadingComponent height="100" />;

  if (error)
    return (
      <ErrorComponent
        heading={error.message}
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const user = userFromState || data?.fetchUser;

  const commonRoutes = () => {
    return (
      <>
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/edit-profile/:step2" element={<EditProfile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Navigate to="/" />} />
        <Route path="/signup" element={<Navigate to="/" />} />
        <Route path="/admin" element={<Navigate to="/" />} />
        <Route path="/forgot-password" element={<Navigate to="/" />} />
        <Route path="/set-password" element={<Navigate to="/" />} />
      </>
    );
  };
  return (
    <Routes>
      <Route element={<StaticPagesLayout />}>
        <Route path="/static-pages/pricing" element={<Pricing />} />
        <Route path="/static-pages/home" element={<Home />} />
        <Route path="/static-pages/contact" element={<Contact />} />
        <Route path="/static-pages/about" element={<About />} />
        <Route path="/static-pages/features" element={<Features />} />
        <Route
          path="/static-pages/termsconditions"
          element={<TermsConditions />}
        />
        <Route path="/static-pages/privacypolicy" element={<PrivacyPolicy />} />
      </Route>
      <Route path="/opt_out/:user_id/:contact_id" element={<OptOutLink />} />
      {authState.isAuthenticated ? (
        <>
          {user?.role === "admin" ? (
            /* If Admin */
            <Route element={<AdminLayout />}>
              <Route path="/admin-panel" element={<AdminPanel />} />
              <Route path="/users" element={<Users />} />
              <Route path="/queries" element={<Queries />} />
              <Route path="/tags" element={<TagsManagement />} />
              <Route
                path="/package-management"
                element={<PackageManagement />}
              />
              <Route path="/archived-packages" element={<ArchivedPackages />} />

              <Route path="/thankyou" element={<Thankyou />} />

              <Route path="/" element={<Navigate to="/admin-panel" />} />
              {commonRoutes()}
            </Route>
          ) : (
            <>
              <Route element={<MainLayout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/contact-management"
                  element={<ContactManagement />}
                />
                <Route path="/import-details" element={<ImportDetails />} />
                <Route path="/errors/:id" element={<Errors />} />
                <Route path="/balance-topup" element={<BalanceTopUp />} />
                <Route path="/stripe_webhooks" element={<BalanceTopUp />} />
                <Route path="/list-management" element={<ListManagement />} />
                <Route path="/archive" element={<Archive />} />
                <Route path="/campaigns" element={<Campaigns />} />
                <Route path="/draft-campaigns" element={<DraftCampaigns />} />
                <Route path="/archived-lists" element={<ArchivedLists />} />
                <Route path="/optout-lists" element={<OptoutList />} />
                <Route
                  path="/manage-contacts/:id"
                  element={<ManageContacts />}
                />
                <Route
                  path="/archived-compaigns"
                  element={<ArchivedCompaigns />}
                />
                <Route
                  path="/archived-templates"
                  element={<ArchivedTemplates />}
                />
                <Route
                  path="/archived-list-view/:id"
                  element={<ArchivedListsViewMore />}
                />
                <Route path="/transactions" element={<Transactions />} />
                {/* <Route path="/transactions" element={<Transactions />} /> */}
                <Route path="/sms-logs" element={<SmsLogs />} />
                <Route path="/templates" element={<Templates />} />

                <Route path="/" element={<Dashboard />} />
                <Route path="/thankyou" element={<Thankyou />} />
                <Route
                  path="/scheduled-campaigns"
                  element={<ScheduledCampaign />}
                />
                <Route
                  path="/inprogress-campaigns"
                  element={<InProgressCampaign />}
                />
                <Route path="/sent-campaigns" element={<SentCampaign />} />
                <Route path="/campaign-stats/:id" element={<CampaignStats />} />

                {commonRoutes()}
              </Route>
            </>
          )}
        </>
      ) : (
        <>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/otp-verification/:email"
              element={<OtpVerification />}
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Route>
          <Route element={<StaticPagesLayout />}>
            <Route path="/" element={<Home />} />
          </Route>
          <Route path="/admin" element={<AdminLogin />} />
        </>
      )}
    </Routes>
  );
};
