import { gql } from "@apollo/client";

export const userAttributes = `
 
  businessName
        city
        companyAddress
        companyName
        closeAccount
        country
        deleted
        email
        fullName
        id
        imageUrl
        invitationAccepted
        phoneNo
        smsCount
        allowTopUpNotification
        revokeAccess
        role
        termsAndConditions
        timeZone
        accountClosureDate
        accountClosureReason
        importingContacts
  `;

export const contactAttributes = `
          archived
            email
            firstName
            id
            lastName
            phoneNo`;

const userObject = `user{
    ${userAttributes}
  }`;

const loginUserAttrinutes = `token
  ${userObject}`;

export const LOGIN = gql`
  mutation loginUser($email: String!, $password: String!, $rememberMe: Boolean!) {
    loginUser(input: {loginAttributes: {email: $email, password: $password, rememberMe: $rememberMe}}) {
      ${loginUserAttrinutes}
      isVerified
        token
    }
  }
`;

export const REGISTER = gql`
  mutation registerUser(
    $fullName: String!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $rememberMe: Boolean!
    $phoneNo: String
    $termsAndConditions: Boolean!
  ) {
    registerUser(
      input: {
        registerAttributes: {
          fullName: $fullName
          email: $email
          password: $password
          passwordConfirmation: $passwordConfirmation
          rememberMe: $rememberMe
          phoneNo: $phoneNo
          termsAndConditions: $termsAndConditions
        }
      }
    ) {
      status
      message
    }
  }
`;
export const OTP_VERIFICATION = gql`
  mutation otpVerification($otpCode: String!, $email: String!) {
    verifyOtp(input: { otpAttributes: { otpCode: $otpCode, email: $email } }) {
      user {
        ${userAttributes}
      }
      token
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser( $fullName: String, $email: String, $image: Upload, $phoneNo: String, $password: String, $passwordConfirmation: String, $timeZone: String, $companyName: String, $businessName: String, $companyAddress: String, $city: String, $country: String, ) {
    updateUser(input: {profileAttributes: {email: $email, fullName: $fullName, phoneNo: $phoneNo, password: $password, passwordConfirmation: $passwordConfirmation, timeZone: $timeZone, companyName: $companyName, businessName: $businessName, companyAddress: $companyAddress, city: $city, country: $country, image: $image}}) {
      ${userAttributes}
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation UpdatePassword(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updatePassword(
      input: {
        currentPassword: $currentPassword
        password: $password
        passwordConfirmation: $passwordConfirmation
      }
    ) {
      response
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact(
    $listIds: [ID!]
    $email: String
    $firstName: String!
    $lastName: String
    $phoneNo: String!
  ) {
    createContact(
      input: {
        contactAttributes: {
          listIds: $listIds
          email: $email
          firstName: $firstName
          lastName: $lastName
          phoneNo: $phoneNo
        }
      }
    ) {
      contact {
       ${contactAttributes}
      }
    }
  }
`;

export const IMPORT_CONTACTS = gql`
  mutation importContacts($file: Upload!, $listId: ID) {
    importContacts(input: { file: $file, listId: $listId }) {
      success
      errors {
        message
      }
      contacts {
        archived
        email
        firstName
        id
        lastName
        optedOut
        phoneNo
      }
    }
  }
`;

export const REMOVE_CONTACT_FROM_LIST = gql`
  mutation removeContactFromList($id: ID, $contactIds: [ID!]) {
    removeContactFromList(
      input: { listAttributes: { id: $id, contactIds: $contactIds } }
    ) {
      list {
        archived
        id
        name
      }
    }
  }
`;
export const CREATE_PACKAGE = gql`
  mutation createPackage(
    $topUpAmount: Float!
    $noOfSms: Float!
    $pricePerSms: Float!
  ) {
    createPackage(
      input: {
        packageAttributes: {
          topUpAmount: $topUpAmount
          noOfSms: $noOfSms
          pricePerSms: $pricePerSms
        }
      }
    ) {
      package {
        id
        noOfSms
        pricePerSms
        topUpAmount
      }
    }
  }
`;

export const UPDATE_PACKAGE = gql`
  mutation updatePackage(
    $id: ID
    $topUpAmount: Float!
    $pricePerSms: Float!
    $noOfSms: Float!
    $archived: Boolean
  ) {
    updatePackage(
      input: {
        packageAttributes: {
          id: $id
          topUpAmount: $topUpAmount
          pricePerSms: $pricePerSms
          noOfSms: $noOfSms
          archived: $archived
        }
      }
    ) {
      package {
        id
      }
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign(
    $name: String!
    $listIds: [ID!]
    $contactIds: [ID!]
    $shortUrlsAttributes: [ShortUrlsAttributes!]
    $senderName: String
    $message: String
    $status: String
    $scheduleDate: String
    $optedOutLink: Boolean
    $sendToAll: Boolean
    $saveAsTemplate: Boolean
  ) {
    createCampaign(
      input: {
        campaignAttributes: {
          name: $name
          listIds: $listIds
          contactIds: $contactIds
          senderName: $senderName
          message: $message
          status: $status
          optedOutLink: $optedOutLink
          scheduleDate: $scheduleDate
          sendToAll: $sendToAll
        }
        shortUrlsAttributes: $shortUrlsAttributes
        saveAsTemplate: $saveAsTemplate
      }
    ) {
      campaign {
        id
        message
        name
        senderName
        status
        lists {
          totalCount
        }
        contacts {
          totalCount
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign(
    $id: ID
    $name: String!
    $listIds: [ID!]
    $contactIds: [ID!]
    $senderName: String
    $message: String
    $status: String
    $scheduleDate: String
    $archived: Boolean
    $shortUrlsAttributes: [ShortUrlsAttributes!]
  ) {
    updateCampaign(
      input: {
        campaignAttributes: {
          id: $id
          name: $name
          senderName: $senderName
          listIds: $listIds
          contactIds: $contactIds
          message: $message
          status: $status
          scheduleDate: $scheduleDate
          archived: $archived
        }
        shortUrlsAttributes: $shortUrlsAttributes
      }
    ) {
      campaign {
        archived
        id
        message
        name
        scheduleDate
        senderName
        status
      }
    }
  }
`;

export const RESEND_CAMPAIGN = gql`
  mutation resendCampaign($id: ID) {
    resendCampaign(input: { campaignAttributes: { id: $id } }) {
      campaign {
        archived
        createdAt
        id
        message
        name
        optedOutLink
        progress
        recipientsCount
        scheduleDate
        senderName
        sentOn
        status
      }
    }
  }
`;

export const UPDATE_LIST = gql`
  mutation updateList($id: ID!, $name: String, $archived: Boolean) {
    updateList(
      input: { listAttributes: { id: $id, name: $name, archived: $archived } }
    ) {
      list {
        archived
        id
        name
      }
    }
  }
`;
export const ASSOCIATE_TAG_TO_LIST = gql`
  mutation addTagInList($id: ID!, $listId: ID!) {
    addTagInList(input: { tagAttributes: { id: $id, listId: $listId } }) {
      tag {
        id
        name
        color
      }
    }
  }
`;
export const CREATE_TAG_TO_LIST = gql`
  mutation createTagInList($name: String, $color: String, $listId: ID) {
    createTagInList(
      input: { tagAttributes: { name: $name, color: $color, listId: $listId } }
    ) {
      tag {
        color
        destroyable
        id
        name
        popular
        userId
      }
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate(
    $name: String!
    $id: ID
    $message: String
    $archived: Boolean
  ) {
    updateTemplate(
      input: {
        templateAttributes: {
          name: $name
          id: $id
          message: $message
          archived: $archived
        }
      }
    ) {
      template {
        archived
        id
        message
        name
      }
    }
  }
`;

export const DISASSOCIATE_TAG_TO_LIST = gql`
  mutation removeTagFromList($id: ID!, $listId: ID!) {
    removeTagFromList(input: { tagAttributes: { id: $id, listId: $listId } }) {
      tag {
        color
        destroyable
        id
        name
        popular
        userId
      }
    }
  }
`;

export const UPDATE_TAG_COLOR = gql`
  mutation updateTag($color: String, $id: ID, $popular: Boolean) {
    updateTag(
      input: { tagAttributes: { color: $color, id: $id, popular: $popular } }
    ) {
      tag {
        color
        destroyable
        id
        name
        popular
        userId
      }
    }
  }
`;
export const DESTROY_TAG = gql`
  mutation destroyTag($id: ID) {
    destroyTag(input: { tagAttributes: { id: $id } }) {
      tag {
        color
        destroyable
        id
        name
        popular
        userId
      }
    }
  }
`;

export const CREATE_LIST = gql`
  mutation createList($name: String) {
    createList(input: { listAttributes: { name: $name } }) {
      list {
        archived
        id
        name
      }
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation createTemplate($name: String!, $message: String) {
    createTemplate(
      input: { templateAttributes: { name: $name, message: $message } }
    ) {
      template {
        archived
        id
        message
        name
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact(
    $id: ID!
    $email: String
    $firstName: String
    $lastName: String
    $phoneNo: String
    $archived: Boolean
    $optedOut: Boolean
    $optedOutBy: String
  ) {
    updateContact(
      input: {
        contactAttributes: {
          archived: $archived
          phoneNo: $phoneNo
          lastName: $lastName
          firstName: $firstName
          email: $email
          id: $id
          optedOut: $optedOut
          optedOutBy: $optedOutBy
        }
      }
    ) {
      contact {
        archived
        email
        firstName
        id
        lastName
        phoneNo
      }
    }
  }
`;

export const OPT_OUT_CONTACT = gql`
  mutation optOutContact(
    $id: ID
    $userId: ID
    $optedOut: Boolean
    $optedOutBy: String
  ) {
    optOutContact(
      input: {
        contactAttributes: {
          id: $id
          userId: $userId
          optedOut: $optedOut
          optedOutBy: $optedOutBy
        }
      }
    ) {
      contact {
        id
      }
    }
  }
`;

export const CHANGE_LIST = gql`
  mutation changeList($contactIds: [ID!], $newListId: ID, $oldListId: ID) {
    changeList(
      input: {
        listAttributes: {
          contactIds: $contactIds
          newListId: $newListId
          oldListId: $oldListId
        }
      }
    ) {
      list {
        archived
        id
        name
      }
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation setPassword($resetPassword: Boolean!, $password: String!, $passwordConfirmation: String!, $token: String!) {
    setPassword(input: {setPasswordAttributes: {resetPassword: $resetPassword, password: $password, passwordConfirmation: $passwordConfirmation, token: $token}}) {
      token
      resource {
        ${userAttributes}
      }
    }
  }
`;

export const SEND_RESET_PASSWORD_INSTRUCTIONS = gql`
  mutation sendResetPasswordInstructions($email: String!) {
    sendResetPasswordInstructions(input: { email: $email }) {
      id
    }
  }
`;
export const RESEND_OTP = gql`
  mutation resendOtpCode($email: String!) {
    resendOtpCode(input: { resendOtpAttributes: { email: $email } }) {
      message
      status
    }
  }
`;

export const REMOVE_IMAGE = gql`
  mutation removeImage($removeImage: Boolean!) {
    removeImage(input: { removeImage: $removeImage }) {
      user{
        ${userAttributes}
      }
    }
  }
`;

export const MODIFY_ACCESS_USER = gql`
  mutation modifyAccessUser($userId: ID!, $revokeAccess: Boolean!) {
    modifyAccessUser(
      input: {
        accessAttributes: { userId: $userId, revokeAccess: $revokeAccess }
      }
    ) {
      id
      revokeAccess
      accountClosureDate
      accountClosureReason
      closeAccount
    }
  }
`;

export const TOP_UP_NOTIFICATION_SWITCH = gql`
  mutation topUpNotification($allowTopUpNotification: Boolean!) {
    topUpNotification(
      input: {
        profileAttributes: { allowTopUpNotification: $allowTopUpNotification }
      }
    ) {
      user {
        allowTopUpNotification
        businessName
        city
        companyAddress
        companyName
        country
        deleted
        email
        fullName
        id
        imageUrl
        invitationAccepted
        phoneNo
        revokeAccess
        role
        smsCount
        termsAndConditions
        timeZone
      }
    }
  }
`;

export const CREATE_CONTACTOR = gql`
  mutation CreateContactor(
    $email: String!
    $fullName: String!
    $enquireAbout: String!
    $message: String!
  ) {
    createContactor(
      input: {
        contactorsAttributes: {
          email: $email
          fullName: $fullName
          enquireAbout: $enquireAbout
          message: $message
        }
      }
    ) {
      contactor {
        id
      }
    }
  }
`;

export const CREATE_CONTACT_US = gql`
  mutation createContactUs(
    $fullName: String!
    $email: String!
    $subject: String!
    $message: String!
    $termsAndConditions: Boolean!
  ) {
    createContactUs(
      input: {
        contactUsAttributes: {
          fullName: $fullName
          email: $email
          subject: $subject
          message: $message
          termsAndConditions: $termsAndConditions
        }
      }
    ) {
      contactUs {
        id
        fullName
        email
        subject
        message
        termsAndConditions
      }
    }
  }
`;

//account close mutation
export const CLOSE_ACCOUNT = gql`
  mutation CloseAccount(
    $closeAccount: Boolean!
    $accountClosureReason: String!
  ) {
    closeAccount(
      input: {
        closeAccountAttributes: {
          closeAccount: $closeAccount
          accountClosureReason: $accountClosureReason
        }
      }
    ) {
      accountClosureDate
      accountClosureReason
      fullName
      id
    }
  }
`;

export const IMPERSONATE_USER = gql`
  mutation impersonateAsUser($userId: ID!) {
    impersonateAsUser(input: { userId: $userId }) {
      impersonatedToken
    }
  }
`;

export const SIGN_IN_WITH_GOOGLE = gql`
  mutation signInWithGoogle($accessToken: String!) {
    signInWithGoogle(input: {accessToken: $accessToken}) {
      ${loginUserAttrinutes}
    }
  }
`;

export const MARK_SENT = gql`
  mutation updateCampaign($id: ID, $status: String) {
    updateCampaign(
      input: { campaignAttributes: { id: $id, status: $status } }
    ) {
      campaign {
        id
        message
        name
        scheduleDate
        senderName
        status
      }
    }
  }
`;
